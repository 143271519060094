<template>
  <TunnelLayout>
    <template #form>
      <div class="flex flex-col">
        <h2 class="font-bold text-2xl text-promy-blue-300 sm:text-base">
          Dernières questions avant <br />
          d’obtenir votre révélation<br />
          de potentiel
        </h2>
        <div class="flex flex-col mt-8 space-y-4 items-baseline w-full">
          <!-- form goes here -->
          <validation-provider
            class="flex flex-col w-full"
            name="form.civilite"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="flex gap-4">
              <pro-radio
                label="Madame"
                :value="form.civilite"
                @change="change($event)"
              >
                Madame
              </pro-radio>
              <pro-radio
                label="Monsieur"
                :value="form.civilite"
                @change="change($event)"
              >
                Monsieur
              </pro-radio>
              <pro-radio
                label="Autre"
                :value="form.civilite"
                @change="change($event)"
              >
                Autre
              </pro-radio>
            </div>
            <div class="w-full">
              <span class="text-red-600 mt-2" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </div>
            <pro-input
              v-if="form.civilite === 'Autre'"
              class="w-full"
              :placeholder="'Autre'"
              :rules="form.civilite === 'Autre' ? 'required' : ''"
              v-model="form.informations.other_civilite"
            />
          </validation-provider>
          <div class="grid grid-cols-1 gap-4 pb-4 w-full">
            <pro-input
              label="Prénom"
              :placeholder="'Prénom'"
              :rules="'required'"
              v-model="form.prenom"
            />
            <pro-input
              label="Nom"
              placeholder="Nom"
              :rules="'required'"
              v-model="form.nom"
            />
            <pro-input
              label="Email"
              placeholder="Email"
              :rules="'required|email'"
              v-model="form.email"
            />
            <pro-input-tel
              label="Téléphone"
              placeholder="Tel"
              :rules="`required|phone:${is_tel_valid},${dialCode}`"
              v-model="form.tel"
              @is_valid="checkTelValidation"
              @dialCode="setDialCode"
            />
          </div>
        </div></div
    ></template>
    <template #buttons>
      <Buttons :form="form" :goNext="invalid || !is_tel_valid"
    /></template>
    <template #right-side>
      <Map :showBtnParcelle="false" :form="form" :cadastre="cadastre" />
    </template>
  </TunnelLayout>
</template>
<script>
import Buttons from './components/Buttons.vue'
import Map from './components/Map.vue'

export default {
  components: {
    Buttons,
    Map,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      required: true,
    },
    invalid: Boolean,
  },
  data() {
    return {
      is_tel_valid: false,
      dialCode: null,
    }
  },
  methods: {
    change(value) {
      this.form.civilite = value
    },
    checkTelValidation(is_valid) {
      let val = this.form.tel.replace(this.dialCode, '')
      this.is_tel_valid = is_valid
    },
    setDialCode(value) {
      this.dialCode = value
    },
  },
}
</script>
